import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Fala, Zé!</h1>
        <h2>Esse é um exemplo de Deploy de uma app React na Cloudflare Pages</h2>
      </header>
    </div>
  );
}

export default App;
